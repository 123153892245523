import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { User } from '../models/user.model';
import { Subject } from "rxjs";

@Injectable()

export class AuthService {

    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;


    private emailSend = new Subject<any>();
    child1$ = this.emailSend.asObservable();


    SendToChild1(msg:string){
        this.emailSend.next(msg);
    }

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) { }

    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return JSON.parse(localStorage.getItem('currentUser'));
        return false;
    }
    public get basicAuthentication() {
        let data = {
            userName: '3rad$9%!@3#!',
            password: '9ra$9%!@3!)&#$@'
        }
        return data;
    }

    public get language() {
        let lang = localStorage.getItem('lang');
        console.log('lang', lang);
        if (lang) {
            return lang;
        }
        return false;
    }

    // login(user: User) {
    //     return this.http.post<any>(this.url + 'user/admin/login', user);
    // }
     login(user: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(this.basicAuthentication.userName + ':' + this.basicAuthentication.password)
            })
        };
        return this.http.post(this.url + 'admin/signin', user, httpOptions);
    }

    reset(data: any) {
        return this.http.post(this.url + 'user/reset-password',data);
    }


    // forgetPassword(email: string) {
    //     const httpOptions = {
    //       headers: new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Basic ' + btoa(this.basicAuthentication.userName + ':' + this.basicAuthentication.password)
    //       })
    //     };
    //     const params = new HttpParams().set('email', email);
    //     return this.http.post(this.url + 'api/admin/forgetPassword', null, { headers: httpOptions.headers, params });
    //   }

      forgetPassword(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(this.basicAuthentication.userName + ':' + this.basicAuthentication.password)
            })
        };
        return this.http.post(this.url + 'admin/forgetPassword',data,httpOptions);
    }
      

    getPostList(pageNumber, limit){
        const url = `${this.url}api/post/reported-post/list?pageNumber=${pageNumber}&limit=${limit}`;
        return this.http.get(url);
    }


    

    logout(data:any) {
        localStorage.removeItem('currentUser');
        // return this.http.post(this.url + 'api/admin/signout', data);
        
    }

    updateuser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    changePassword(data: any) {
        return this.http.put(this.url + 'admin/changePassword', data);
    }

    emailValidation(data:any){
        return this.http.post(this.url + "user/emailExists", data);
    }


}