

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageCaregiversService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data : any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }


    getCaregiverList(page,pageLimit){
        const url = `${this.url}admin/getTopListing?page=${page}&pageLimit=${pageLimit}`;
        return this.http.get(url);
    }

    approveCaregiver(data:any){
        return this.http.post(this.url + "user/approve-caregiver", data);
    }

    updatetoplisting(data: any){

        return this.http.post(this.url + "admin/approveTopListing", data);

    }

    getCaregiverById(data:any){
        return this.http.post(this.url + "user/view-caregiver", data);
    }

    setStatus(data:any){
        return this.http.post(this.url + "user/change-status", data);
    }

   

}