<app-layout></app-layout>

<div class="news-header">
  
</div>
<div class="content">
  <div class="row">
    <div class="col-12 total-counts" routerLink="/analytics-dashboard/registered-experiencers">
      <mat-card class="total-count">
        <div class="card-body">
          <div class="align-items-center d-flex justify-content-center">
            <img class="card-img-top" src="../../../assets/little/SVG_LOGO/SVG_LOGO/Devices.svg" alt="Devices Icon" class="pr-2" />
            <h1 style="font-size: 20px; font-weight: bold">{{ registeredcustomer }}</h1>
          </div>
          <div class="align-items-center d-flex justify-content-center mt-2">
            <h5 class="card-title text-center" style="font-size: 20px; line-height: normal">Total number of registered Customers</h5>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-12 total-counts" routerLink="/manage/businesses">
      <mat-card class="total-count">
        <div class="card-body">
          <div class="align-items-center d-flex justify-content-center">
            <img class="card-img-top" src="../../../assets/little/SVG_LOGO/SVG_LOGO/Active age group.svg" alt="Active Age Group Icon" class="pr-2" />
            <h1 style="font-size: 20px; font-weight: bold">{{ registeredbusiness }}</h1>
          </div>
          <div class="align-items-center d-flex justify-content-center mt-2">
            <h5 class="card-title text-center" style="font-size: 20px; line-height: normal">Total number of registered Businesses</h5>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-12 total-counts">
      <mat-card class="total-count">
        <div class="card-body">
          <div class="align-items-center d-flex justify-content-center">
            <img class="card-img-top" src="../../../assets/little/SVG_LOGO/SVG_LOGO/View Feedback.svg" alt="Devices Icon" class="pr-2" />
            <h1 style="font-size: 20px; font-weight: bold">{{ appointment }}</h1>
          </div>
          <div class="align-items-center d-flex justify-content-center mt-2">
            <h5 class="card-title text-center" style="font-size: 20px; line-height: normal">Total number of Bookings</h5>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
