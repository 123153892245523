import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'file/upload', data);
    }


    createService(data:any){
        return this.http.post(this.url + 'admin/service-add', data);
    }

    getServiceList(page,pageLimit){
        const url = `${this.url}admin/service-list?page=${page}&pageLimit=${pageLimit}`;
        return this.http.get(url);

        // return this.http.get(this.url + 'admin/service-list', data);
    }

    




    getSearchList(params: any) {
        // Use HttpParams to construct query parameters
        let queryParams = new HttpParams();
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                queryParams = queryParams.append(key, params[key]);
            }
        }
    
        return this.http.get(this.url + 'admin/search-services', { params: queryParams });
    }

    getCategoryList(data:any){
        return this.http.post(this.url + 'admin/category/list', data);
    }

    categoryList(data:any){
        return this.http.post(this.url + `admin/subcategory/list`,data);
    }

    

    getClientList(data:any){
        
        return this.http.post(this.url + 'admin/listProfile', data)
    }





   

    

    getServices(data:any){
        // return this.http.post(this.url + 'service/list', data);
    }

    deleteService(serviceId: string) {
        const httpOptions = {
          headers: new HttpHeaders({}),
        };
      
        return this.http.delete(this.url + 'admin/service-delete/' + serviceId, httpOptions);
      }
      
      

    

    editService(data:any){
        return this.http.put(this.url + 'admin/service-update', data);
    }
}