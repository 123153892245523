import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/components/login/login.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "manage/businesses",
        loadChildren: () =>
          import("./views/manage-businesses/manage-businesses.module").then(
            (m) => m.ManageClientsModule
          ),
      },

      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      



      {
        path: "reporting",
        loadChildren: () =>
        import("../app/dashboard/dashboard.module").then(
          (m) => m.DashboardModule
        )
      },

      {
        path: "toplistings",
        loadChildren: () =>
          import("./views/manage-toplistings/manage-toplistings.module").then(
            (m) => m.ManageCaregiversModule
          ),
      },


    



      {
        path: "analytics-dashboard",
        loadChildren: () =>
          import("./views/manage-analytics/manage-analytics.module").then(
            (m) => m.ManageTaxesModule
          ),
      },

      

      {
        path: "manage/categories",
        loadChildren: () =>
          import("./views/manage-servicecategories/manage-servicecategories.module").then(
            (m) => m.ManageDiseasesModule
          ),
      },

      {
        path: "manage/subcategory",
        loadChildren: () =>
          import("./views/manage-sub/manage-sub.module").then(
            (m) => m.ManageSubModule
          ),
      },


      {
        path: "services",
        loadChildren: () =>
          import("./views/manage-service/manage-service.module").then(
            (m) => m.ManageServiceModule
          ),
      },

    ],
  },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
