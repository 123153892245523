import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getDashboard(data:any){
        return this.http.post(this.url + "appList/getDashboardAnalytics", data );
    }

    getDashboardCount(){
        return this.http.get(this.url + "admin/dashboard-report" );
    }


    getDownloadAppList(){
        return this.http.get(this.url + "appList/getTotalAppsDownloaded");
    }


    getActiveUsers(){
        return this.http.get(this.url + "appList/getHospitalsUsers");
    }


    // getActiveUsers()

    getTotalForms(){
        return this.http.get(this.url + "appList/getTotalForms");
    }

    getAgeGroup(data){
        return this.http.post(this.url + "appList/getAgeGroup", data);
    }

    deviceCount(){
        return this.http.get(this.url + "appList/getDeviceCountByHospital");
    }

    getDeviceModel(id:any){
        return this.http.post(this.url + "appList/getDeviceListsByHospital", id);
    }


    // getAnalytics(data:any){
    //     return this.http.post(this.url + "user/analytics-report",data);
    // }

}